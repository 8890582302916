import Vue from "vue";
import Component from "vue-class-component";
import { generateEmptyPropertyCharacteristic } from "@/utils/model-helpers";

// Temp component to have less clutter in the property/create component
@Component
export class HasPropertyOptions extends Vue {
  readonly roomTypes: Array<{ label: string; value: string }> = [
    {
      value: "CELLAR",
      label: "Kelder",
    },
    {
      value: "ENTRANCE_HALL",
      label: "Inkomhal",
    },
    {
      value: "TOILET",
      label: "Toilet",
    },
    {
      value: "LAVATORY",
      label: "Wasplaats",
    },
    {
      value: "LIVING_ROOM",
      label: "Woonkamer",
    },
    {
      value: "KITCHEN",
      label: "Keuken",
    },
    {
      value: "SCULLERY",
      label: "Bijkeuken",
    },
    {
      value: "STORAGE",
      label: "Berging",
    },
    {
      value: "NIGHT_HALL",
      label: "Nachthal",
    },
    {
      value: "BEDROOM",
      label: "Slaapkamer",
    },
    {
      value: "DRESSING",
      label: "Dressing",
    },
    {
      value: "BATHROOM",
      label: "Badkamer",
    },
    {
      value: "ATTIC",
      label: "Zolder",
    },
    {
      value: "OFFICE",
      label: "Bureau",
    },
    {
      value: "COMMERCIAL_ROOM",
      label: "Commerciele ruimte",
    },
    {
      value: "ANNEX",
      label: "Bijgebouw",
    },
    {
      value: "GARDEN_SHED",
      label: "Tuinhuis",
    },
    {
      value: "PORCH",
      label: "Veranda",
    },
    {
      value: "TERRACE",
      label: "Terras",
    },
    {
      value: "GARAGE",
      label: "Garage",
    },
    {
      value: "OTHER",
      label: "Andere",
    },
  ];

  yesNoOption: Array<{ label: string; value: boolean }> = [
    { label: "Ja", value: true },
    { label: "Neen", value: false },
  ];

  yesNoConformOption = [
    { label: "Ja, conform", value: "YES_CONFORM" },
    { label: "Ja, niet conform", value: "YES_NOT_CONFORM" },
    { label: "Neen", value: "NO" },
  ];

  // TODO: Remove after PF refactor
  propertyFloodRiskOPeilOptions = [
    { value: "FLOOD_RISK", label: "Effectief overstromingsgevoelig gebied" },
    { value: "POTENTIAL_FLOOD_RISK", label: "Mogelijk overstromingsgevoelig gebied" },
    { value: "NO_FLOOD_RISK", label: "Niet gelegen in een overstromingsgevoelig gebied" },
  ];

  // TODO: Remove after PF refactor
  propertyFloodRisk2023Options = [
    { value: "A", label: "Klasse A (geen kans)" },
    { value: "B", label: "Klasse B (kleine kans onder klimaatverandering sc2050)" },
    { value: "C", label: "Klasse C (kleine kans onder huidig klimaat)" },
    { value: "D", label: "Klasse D (middelgrote kans onder huidig klimaat)" },
  ];

  // TODO: Remove after PF refactor
  propertyTerrainDestinationOptions = [
    { value: "AREA_WITH_ECONOMIC_ACTIVITIES", label: "gebied met economische activiteiten" },
    { value: "WINNING_AREA", label: "winningsgebied" },
    { value: "GROUND_RESERVE_AREA", label: "grondreservegebied" },
    { value: "FOREST_AREA", label: "bosgebied" },
    { value: "INDUSTRIAL_AREA", label: "industriegebied" },
    { value: "RECREATION_AREA_DAY", label: "gebied voor dagrecreatie" },
    { value: "RECREATION_AREA_RESIDENTIAL", label: "gebied voor verblijfsrecreatie" },
    { value: "MIXED_RESIDENTIAL_AREA", label: "gemengd woongebied" },
    { value: "NATURE_AREA", label: "natuurgebied" },
    { value: "PARK_AREA", label: "parkgebied" },
    { value: "RESIDENTIAL_AREA", label: "woongebied" },
    { value: "RESIDENTIAL_AREA_CULT_HIST_ESTHETIC", label: "woongebied met een cult., hist. en/of esthetische waarde" },
    { value: "RESIDENTIAL_AREA_RURAL_CHARACTER", label: "woongebied met landelijk karakter" },
    { value: "RESIDENTIAL_PARK", label: "woonpark" },
    { value: "RESIDENTIAL_EXPANSION_AREA", label: "woonuitbreidingsgebied" },
    { value: "GREEN_AREA", label: "groengebied" },
    { value: "NATURE_RESERVE", label: "natuurreservaat" },
    { value: "LANDSCAPE_VALUABLE_AGRICULTURAL_AREA", label: "landschappelijk waardevol agrarisch gebied" },
    { value: "INDUSTRIAL_AREA_CRAFT_ENTERPRISES", label: "industriegebied voor ambachtelijke bedrijven of gebieden voor kleine en middelgrote ondernemingen" },
    { value: "OTHER_GROUND_USAGES", label: "gebieden bestemd voor ander grondgebruik" },
    { value: "URBAN_DEVELOPMENT", label: "gebied voor stedelijke ontwikkeling" },
  ];

  // TODO: Remove after PF refactor
  propertyLegalSummonsOptions = [
    { value: "LEGAL_SUMMON_ISSUED", label: "dagvaarding uitgebracht" },
    { value: "JUDICIAL_RESTITUTION_IMPOSED", label: "rechterlijke herstelmaatregel opgelegd" },
    { value: "ADMINISTRATIVE_COERCION_IMPOSED", label: "bestuursdwang opgelegd" },
    { value: "INJUNCTION_UNDER_THREAT_OF_FINE_IMPOSED", label: "last onder dwangsom opgelegd" },
    { value: "ENTERED_VOLUNTARY_SETTLEMENT", label: "minnelijke schikking aangegaan" },
    { value: "NO_JUDICIAL_RESTITUTION_OR_ADMINISTRATIVE_MEASURE_IMPOSED", label: "geen rechterlijke herstelmaatregel of bestuurlijke maatregel opgelegd" },
  ];

  payload: PropertyCharacteristics = generateEmptyPropertyCharacteristic();
}
