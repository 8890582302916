
  import { HasPropertyConfig, PropertyInfoBlockValue } from "@/mixins/properties/has-config";
  import { HasPropertyTabs } from "@/mixins/properties/has-tabs";
  import { HasObjectMethods } from "@/mixins/has-object-methods";
  import { HasPropertyOptions } from "@/mixins/has-property-options";

  import { Component, Mixins } from "vue-property-decorator";
  import { formatCurrency, formatDate } from "@/utils/formatters";

  @Component({
    components: {
      PropertyPartGeneralInfoBlock: () => import("@/views/auth/properties/parts/general/info-block.vue"),
      PropertyPartViewerRooms: () => import("@/views/auth/properties/parts/viewer/rooms.vue"),
    },
  })
  export default class PagePropertyViewer extends Mixins(HasPropertyTabs, HasPropertyConfig, HasObjectMethods, HasPropertyOptions) {
    groupedTabs: any = [];

    /**
     * @name After mounted
     * @description Handle the event after all data is generated and the form has mounted.
     * - Update the current tab based on the url hash.
     *
     */
    async afterMounted() {
      this.$nextTick(() => {
        this.handleTabsUpdate();
        this.createGroupedtabs();
      });
    }

    /**
     * @name Render tab value
     * @description Render the value of the tab value as readable text.
     *
     * @param value - The raw value of the tab value.
     * @param type - The type of the tab value.
     */
    renderTabValue(tabValue: PropertyInfoBlockValue & { value: string | string[] | boolean | number | PropertyRoom[] }) {
      const { value, type, props } = tabValue;

      // if the value is undefined, null or the tabValue has no type, render a dash
      if (value === undefined || value === null || !type) {
        return "-";
      }

      if (type === "yes-no") return this.$t(`general.${value ? "yes" : "no"}`);
      if (type === "money") return formatCurrency(value as string);
      if (type === "date") return formatDate(value as string);
      if (type === "subtypes") return this.$t(`property.subtypes.${value as string}`);

      if (["number", "textinput", "textarea", "modality-notes"].includes(type)) {
        if (Array.isArray(value)) {
          return "<ul>" + (value as string[]).map((v: string) => `<li class='mb-2 last:mb-0'>${v}</li>`).join("") + "</ul>";
        }
        return `${value}${props?.suffix ? `${props.suffix} ` : ""}`;
      }

      if (type === "select" || type === "multiselect") {
        // some multiselect values are not arrays, but single values
        if (!Array.isArray(value)) {
          return (props && props.options?.find((option) => `${option.value}` === `${value}`)?.label) || value;
        }
        return (value as string[]).map((v: string) => (props && props.options?.find((option) => `${option.value}` === `${v}`)?.label) || v).join(", ");
      }

      return value;
    }

    /**
     * @name Get Property tabs with real values
     * @description
     * Map the propertyTabs where to a new array where we replace the values with the nested values
     * so we don't have to get the nested values twice in the template.
     *
     */
    createGroupedtabs() {
      this.groupedTabs = this.propertyTabs
        .filter((tab) => tab != null)
        .map((tab) => {
          // Group the values by the 'group' property
          let groupedValues = tab.values
            .filter((v) => v.allowed)
            .reduce((grouped, tabValue) => {
              const group = tabValue.group || "default";

              let value = {
                label: tabValue.label,
                type: tabValue.type,
                key: tabValue.key,
                allowed: tabValue.allowed,
                group: group,
                cols: tabValue.cols,
                props: tabValue.props,
                value: this.payload ? this.getNestedValue(this.payload.characteristics as object, tabValue.key) : null,
              };

              // If the group doesn't exist yet, create it
              // @ts-ignore
              if (!grouped[group]) {
                // @ts-ignore
                grouped[group] = [];
              }

              // Add the value to the group
              if ((value.value !== null && value.value !== undefined) || value.type === "label") {
                if (Array.isArray(value.value) && value.value.length === 0) {
                } else {
                  // @ts-ignore
                  grouped[group].push(value);
                }
              }

              return grouped;
            }, {});

          return {
            id: tab.id,
            title: tab.title,
            description: tab.description,
            icon: tab.icon,
            groups: groupedValues as { [key: string]: Array<PropertyInfoBlockValue & { value: string | string[] | boolean | number | PropertyRoom[] }> },
          };
        });
    }

    /**
     * @name Has valid sub groups
     * @description Return true if all values in the nested group match all conditions below:
     * - groups must exist
     * - groups must not be empty
     * - at least one group should have values
     *
     * @param groupValues
     */
    hasValidSubGroups(groups: { [key: string]: Array<PropertyInfoBlockValue & { value: string | string[] | boolean | number | PropertyRoom[] }> }) {
      return groups && Object.values(groups).length !== 0 && Object.keys(groups).some((groupKey) => this.hasEmptyGroups(groups[groupKey], groupKey));
    }

    /**
     * @name Has empty groups
     * @description Return true if all values in the group match at least one of the following conditions
     * - value is null
     * - value is undefined
     * - value is empty string
     * - value is empty array
     *
     * @param groupValues
     */
    hasEmptyGroups(groupValues: Array<PropertyInfoBlockValue & { value: string | string[] | boolean | number | PropertyRoom[] }>, group: string) {
      return (
        groupValues.length !== 0 ||
        groupValues
          .filter((gv) => gv.group && gv.group === group)
          .every((groupValue) => {
            return groupValue.value === null || groupValue.value === undefined || groupValue.value === "" || (Array.isArray(groupValue.value) && groupValue.value.length === 0);
          })
      );
    }
  }
